import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"
import intensiveMoisturiserProduct from "../images/product-and-package/inm.jpg"
import intensiveMoisturiserImage from "../images/products-without-packaging/moisturiser.png"

// related product images
import cherryMoisturiserProduct from "../images/product-and-package/mc.jpg"
import medPlusProduct from "../images/product-and-package/mp.jpg"

import bootsLogo from "../images/stockists/boots.png"
import tescoLogo from "../images/stockists/tesco.png"
import superdrugLogo from "../images/stockists/superdrug.png"
import morrisonsLogo from "../images/stockists/morrisons.png"
import sainsburysLogo from "../images/stockists/sainsburys.png"
//import wilkoLogo from "../images/stockists/wilko.png"
import bodycareLogo from "../images/stockists/bodycare.png"
import saversLogo from "../images/stockists/savers.png"

import coldIcon from "../images/icons/product-icon/winter.svg"
import everydayIcon from "../images/icons/product-icon/everyday.svg"
import outdoorIcon from "../images/icons/product-icon/outdoors.svg"
import overnightIcon from "../images/icons/product-icon/overnight.svg"

const product = {
  image: intensiveMoisturiserProduct,
  productImage: intensiveMoisturiserImage,
  title: <h1 className="product-overview__heading">Intensive Moisturiser.</h1>,
  alt: "Intensive Moisturiser.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      Blistex Intensive Moisturiser is infused with a blend of rich moisturisers
      like Shea Butter to condition, Camphor to soothe and Allantoin to smooth.
      <br />
    </h2>
  ),
  disclaimer: "",
  icons: [
    { title: "everyday", icon: everydayIcon },
    { title: "outdoors", icon: outdoorIcon },
    { title: "cold weather", icon: coldIcon },
    { title: "at night", icon: overnightIcon },
  ],
  overviewCopy: (
    <div>
      <br />
      <p>It moisturises deep down to intensely rehydrate your lips.</p>
    </div>
  ),
  listID: "",
  stockists: [
    {
      stockistName: "Boots",
      stockistImage: bootsLogo,
      stockistLink:
        "https://www.boots.com/blistex-intensive-moisturiser-hydrating-lip-cream-spf10-5g-10003619",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Tesco",
      stockistImage: tescoLogo,
      stockistLink: "https://www.tesco.com/groceries/en-GB/products/250653921",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Superdrug",
      stockistImage: superdrugLogo,
      stockistLink:
        "https://www.superdrug.com/Blistex/Blistex-Intensive-Moisturiser-Hydrating-SPF-10-5g/p/742619",
      classModifier: "border-bottom",
      showLink: "true",
    },
    {
      stockistName: "Morrisons",
      stockistImage: morrisonsLogo,
      stockistLink:
        "https://groceries.morrisons.com/webshop/product/Blistex-Lipcare-Intensive-Moisuriser-/268599011",
      classModifier: "",
      showLink: "false",
    },
    {
      stockistName: "Sainsbury's",
      stockistImage: sainsburysLogo,
      stockistLink:
        "https://www.sainsburys.co.uk/shop/gb/groceries/lip-care-448415-44/blistex-intensive-moisturiser-spf-10-5g",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Savers",
      stockistImage: saversLogo,
      stockistLink:
        "https://www.savers.co.uk/Skin-Care/Lip-Care/Dry-%26-Chapped-Lip-Treatments/Blistex-Intensive-Moisturiser-SPF-10-5g/p/742619",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Bodycare",
      stockistLink:
        "https://www.bodycareonline.co.uk/product/blistex-intensive-moisturiser-5g/",
      stockistImage: bodycareLogo,
      classModifier: "",
      showLink: "",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
        gently massage all over your lips every day. re-apply as often as you
        like, especially if you're out and about in dry, cold or sunny weather.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      aqua, hydrogenated polyisobutene, cera alba, petrolatum, ethylhexyl
      methoxycinnamate, sorbitan isostearate, alcohol denat., hydrogenated soy
      polyglycerides, glycerin, polysorbate 60, polyglyceryl-3 diisostearate,
      cetyl alcohol, stearyl behenate, mentha piperita (peppermint) oil,
      allantoin, aroma, camphor, dimethicone, olea europaea (olive) fruit oil,
      ammonium hydroxide, arachidyl alcohol, arachidyl glucoside, behenyl
      alcohol, butyrospermum parkii (shea) butter, C15-23 alkane, calcium
      disodium EDTA, citric acid, hydrated silica, lanolin, lauric acid,
      limonene, linalool, myristic acid, oleic acid, palmitic acid, phosphoric
      acid, sodium hydroxide, sodium saccharin, sorbitan olivate, thymol.
    </p>
  ),
  faqs: [
    {
      question: "Does Intensive Moisturiser contain a sun protection factor?",
      answer: "Yes, Intensive Moisturiser contains SPF 10.",
    },
    {
      question:
        "The packaging for Intensive Moisturiser has changed - is the formula still the same?",
      answer:
        "Yes, although the packaging has been updated, the same trusted formulas remain.",
    },
    {
      question: "Can I use Intensive Moisturiser overnight?",
      answer:
        "Absolutely. Intensive Moisturiser is made up of a blend of moisturisers that will intensely rehydrate your lips while you sleep.",
    },

    {
      question:
        "Does this product contain any ingredients derived from animals?",
      answer: "Yes, Beeswax.",
    },
    {
      question: "Do you sell this product online? ",
      answer:
        "Intensive Moisturiser is available to buy online through key retailers.  Please click on the where to buy link on each product page.",
    },
    {
      question: "Does this product contain perfume?",
      answer: "No, only flavourings.",
    },
    {
      question: "Does this product contain any parabens?",
      answer: "No.",
    },
    {
      question: "Is your packaging recyclable?",
      answer:
        "The backing card used in our packaging is fully recyclable, however the applicator is not yet recyclable.",
    },
  ],
  relatedProducts: [
    {
      productName: "Intensive Moisturiser Cherry.",
      productLink: "/intensive-moisturiser-cherry",
      productImage: cherryMoisturiserProduct,
    },
    {
      productName: "MedPlus Jar.",
      productLink: "/medplus",
      productImage: medPlusProduct,
    },
  ],
}

const IntensiveMoisturiser = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="Intensive Moisturiser." />
    <SingleProduct data={product} inStock={true} outOfStockMsg={null} />
  </Layout>
)

export default IntensiveMoisturiser
